import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { Subscription, interval, BehaviorSubject } from "rxjs";
import { filter, take } from "rxjs/operators";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { KeyMap, Tag, UserPermissions } from "../../../models/shared";

import { AutomationComponent } from "../automation/automation.component";
import { AutomationService } from "../automation.service";
import { TaskSet } from "../automation";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { TitleCasePipe } from "@angular/common";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";

@Component({
    selector: "app-automation-list",
    templateUrl: "./automation-list.component.html",
    providers: [TitleCasePipe, TimezonePipe]
})
export class AutomationListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;

    taskSets: TaskSet[];
    taskSetName: string;

    sortedByText = "NONE";
    isAdmin: boolean;
    resourceTags: Tag[];
    urls = Constants.urls;

    columns: {
        number_of_tasks: boolean;
        number_of_schedules: boolean;
        next_scheduled_run: boolean;
        status: boolean;
    };

    userPermissions: UserPermissions;

    @ViewChild(RouterOutlet) automation: RouterOutlet;

    private routeSubscription: Subscription;
    private taskSetsSubscription: Subscription;
    private taskSetsRefreshSubscription: Subscription;

    private taskSetsBS$ = new BehaviorSubject<TaskSet[]>([]);

    selectedRows: TaskSet[] = [];

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 200,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TaskSet>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<TaskSet>): string => row.name,
            textValue: (row: KeyMap<TaskSet>): string => row.name
        },
        {
            header: this.translate.instant("#_OF_TASKS"),
            columnDef: "tasksCount",
            width: 80,
            visible: true,
            align: "right",

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TaskSet>>(
                (row: KeyMap<TaskSet>): string => row.tasks.length.toString(),
                (row: KeyMap<TaskSet>): string => row.tasks.length.toString(),
                (): boolean => (!this.taskSetName && this.sortedByText === "#_OF_TASKS") || this.columns.number_of_tasks
            ),
            sortBy: (row: KeyMap<TaskSet>): number => row.tasks.length
        },
        {
            header: this.translate.instant("#_OF_SCHEDULES"),
            columnDef: "schedulesCount",
            width: 80,
            visible: true,
            align: "right",

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TaskSet>>(
                (row: KeyMap<TaskSet>): string => row.schedules.length.toString(),
                (row: KeyMap<TaskSet>): string => row.schedules.length.toString(),
                (): boolean =>
                    (!this.taskSetName && this.sortedByText === "#_OF_SCHEDULES") || this.columns.number_of_schedules
            ),
            sortBy: (row: KeyMap<TaskSet>): number => row.schedules.length
        },
        {
            header: this.translate.instant("NEXT_SCHEDULED_RUN"),
            columnDef: "nextScheduledRun",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TaskSet>>(
                (row: KeyMap<TaskSet>): string => this.getNextExecutionTimeText(row),
                (row: KeyMap<TaskSet>): string => this.getNextExecutionTimeText(row),
                (row: KeyMap<TaskSet> | unknown): boolean =>
                    !!(row as unknown as TaskSet)._frontData.next_execution_time
            ),
            sortBy: (row: KeyMap<TaskSet>): string => this.getNextExecutionTimeText(row)
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 80,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TaskSet>>(
                (row: KeyMap<TaskSet>): string => this.titleCasePipe.transform(row.state),
                (row: KeyMap<TaskSet>): string => this.titleCasePipe.transform(row.state),
                (): boolean => (!this.taskSetName && this.sortedByText === "STATUS") || this.columns.status
            ),
            sortBy: (row: KeyMap<TaskSet>): string => this.titleCasePipe.transform(row.state)
        }
    ];

    constructor(
        private router: Router,
        private as: AutomationService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titleCasePipe: TitleCasePipe,
        private timeZonePipe: TimezonePipe
    ) {
        // Set Title
        this.titleService.setTitle("AUTOMATION", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.name) {
                    this.taskSetName = event.snapshot.params.name;
                    const existingTaskSet = this.taskSets?.find(taskSet => this.taskSetName === taskSet.name);
                    this.selectedRows = existingTaskSet ? [existingTaskSet] : [];
                } else {
                    this.taskSetName = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // resourceTags
        this.sharedService
            .getResourceTagsByType("task_sets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");
        this.columns = localStorage.getItem("automation.visibleColumns")
            ? JSON.parse(localStorage.getItem("automation.visibleColumns"))
            : {
                  number_of_tasks: true,
                  number_of_schedules: true,
                  next_scheduled_run: true,
                  status: true
              };

        this.taskSetsSubscription = this.as.taskSets.subscribe(taskSets => {
            this.taskSets = taskSets;
            if (this.taskSets) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // Start Auto Refresh
        this.startTaskSetsRefresh();

        setTimeout(() => {
            if (this.taskSetName) {
                const existingTaskSet = this.taskSets.find(taskSet => this.taskSetName === taskSet.name);
                this.selectedRows = existingTaskSet ? [existingTaskSet] : [];
            }
        }, 0);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.taskSetsSubscription.unsubscribe();
        this.stopTaskSetsRefresh();
    }

    selectRow(taskSet: TaskSet) {
        this.router.navigate([Constants.urls.automation, taskSet.name]);
    }

    async refresh() {
        this.refreshing = true;
        const taskSetsRefresh = this.as.refreshTaskSets(true).toPromise();
        const taskSetRefresh = this.taskSetName ? this.as.refreshTaskSet(this.taskSetName, true).toPromise() : null;
        const taskSetComponentRefresh =
            this.automation && this.automation.component
                ? (this.automation.component as AutomationComponent).refresh()
                : null;

        await Promise.all([taskSetsRefresh, taskSetRefresh, taskSetComponentRefresh]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (taskSet: TaskSet) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "TASK_SET", this.selectedRows, func);

        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple task sets");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (taskSet: TaskSet) => this.as.deleteTaskSet(taskSet));
    }

    async multiEdit() {
        await this.modalService.editMultiple("TASK_SET", this.selectedRows, async (ts: TaskSet, model) => {
            return this.as.updateTaskSet(ts, model);
        });
    }

    startTaskSetsRefresh() {
        this.taskSetsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopTaskSetsRefresh() {
        this.taskSetsRefreshSubscription.unsubscribe();
    }

    get task_sets$() {
        return this.taskSetsBS$.asObservable();
    }

    private prepTableData() {
        if (this.taskSets) {
            this.taskSetsBS$.next([...this.taskSets]);
        }
    }

    taskSetFilter = (taskSet: TaskSet, term: string) => {
        return (
            // Name
            taskSet.name.toLowerCase().includes(term.toLowerCase()) ||
            // Access Tags
            Object.values(taskSet.resourceTags).some(tag => tag.name.toLowerCase().includes(term.toLowerCase())) ||
            // Status
            taskSet.state.toLowerCase().includes(term.toLowerCase())
        );
    };

    getNextExecutionTimeText(taskSet: TaskSet): string {
        let result = "";

        if (taskSet._frontData.next_execution_time) {
            result = `${taskSet._frontData.next_execution_time} ${
                this.timeZonePipe.transform(taskSet._frontData.next_execution_timezone, "utc").text
            }`;
        }

        return result;
    }
}
