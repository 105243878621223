import { Component, OnInit, OnDestroy, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";

import { Constants } from "../../../constants/constants";
import { ZxEventsComponent } from "../../../components/shared/zx-events/zx-events.component";
import { Tag, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

import { AutomationService } from "../automation.service";
import { TaskSet } from "../automation";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import moment from "moment-timezone";

@Component({
    selector: "app-automation",
    templateUrl: "./automation.component.html"
})
export class AutomationComponent implements OnInit, OnDestroy {
    taskSet: TaskSet;
    taskSetName: string;
    resourceTags: Tag[];

    viewOption = "accordions";
    view = "accordions";
    userPermissions: UserPermissions;

    activeTab: string;
    tabsetHasScroll: boolean;

    timeZones = Constants.timeZones;
    saving: boolean;
    canEdit: boolean;

    private taskSetsSubscription: Subscription;
    private detailPanelViewSubscription: Subscription;

    loadingDetails = true;

    @ViewChild(ZxEventsComponent) zxEvents: ZxEventsComponent;
    @ViewChild("taskSetTabset") taskSetTabset: NgbNav;

    @HostListener("window:resize", [])
    private onResize() {
        this.hasScroll();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private as: AutomationService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.taskSetName = params.get("name");
            if (this.taskSetName) this.taskSet = this.as.getCachedTaskSet(this.taskSetName);
            if (!this.taskSet) return this.cancel();

            // Set Title
            this.titleService.setTitle("AUTOMATION", "", this.taskSet);

            // Loaded Details?
            if (!this.taskSet.hasFullDetails) this.loadingDetails = true;
            this.as.refreshTaskSet(this.taskSetName, true);
        });
    }

    ngOnInit() {
        // Get view from local storage
        if (localStorage.getItem("detail-panel-view")) {
            this.sharedService.setDetailPanelView(localStorage.getItem("detail-panel-view"));
            this.viewOption = localStorage.getItem("detail-panel-view");
        } else {
            this.sharedService.setDetailPanelView("accordions");
            this.viewOption = "accordions";
        }

        this.taskSetsSubscription = this.as.taskSets.subscribe(taskSets => {
            this.taskSet = taskSets.find((ts: TaskSet) => ts.name === this.taskSetName);
            if (this.taskSet && this.taskSet.hasFullDetails) this.loadingDetails = false;
            this.updateCanEdit();
        });

        this.sharedService
            .getResourceTagsByType("task_sets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
                this.updateCanEdit();
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateCanEdit();
        });

        this.detailPanelViewSubscription = this.sharedService.getDetailPanelView.subscribe(val => {
            this.view = val;
        });

        // Determine if scrollbar is needed for tabs
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }

    ngOnDestroy() {
        if (this.taskSetsSubscription) this.taskSetsSubscription.unsubscribe();
        this.detailPanelViewSubscription.unsubscribe();
    }

    refresh() {
        if (this.zxEvents) this.zxEvents.refresh();
    }

    hasScroll() {
        if (this.taskSetTabset) {
            if (
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollWidth >
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].clientWidth
            ) {
                this.tabsetHasScroll = true;
            } else {
                this.tabsetHasScroll = false;
            }
        }
    }

    scrollLeft() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 0;
    }

    scrollRight() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 1000;
    }

    cancel() {
        this.gotoTaskSets();
    }

    gotoTaskSets() {
        this.router.navigate([Constants.urls.automation]);
    }

    async deleteTaskSet() {
        await this.modalService.confirm(
            "DELETE",
            "TASK_SET",
            async () => {
                const id = this.taskSet.id;
                const result = await this.as.deleteTaskSet(this.taskSet);
                if (result) {
                    this.mixpanelService.sendEvent("delete task set", { id });
                    this.gotoTaskSets();
                } else {
                    return false;
                }
            },
            this.taskSet.name
        );
    }

    editTaskSet(name: string): void {
        this.router.navigate([Constants.urls.automation, name, "edit"]);
    }

    cloneTaskSet(name: string): void {
        this.router.navigate([Constants.urls.automation, name, "clone"]);
    }

    async runTaskSet(taskSet: TaskSet) {
        this.saving = true;

        const date = moment();
        date.minutes(date.minutes() + 2);
        date.seconds(0);

        // Timezone
        const selectedTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone ??
            this.timeZones.find(tz => tz.offset === new Date().getTimezoneOffset() / -60)?.utc[0];

        const model = {
            name: "Manual run",
            is_enabled: true,
            period_type: "once",
            period_param: 1,
            start_time: date.tz("UTC").format(),
            timezone: selectedTimeZone
        };

        const result = await this.as.addSchedule(taskSet, model);
        if (result) {
            this.as.refreshTaskSet(this.taskSetName, true);
            this.saving = false;
        } else this.saving = false;
    }

    private updateCanEdit() {
        this.canEdit =
            this.taskSet && this.sharedService.canEditZixiObject(this.taskSet, this.resourceTags, this.userPermissions);
    }

    viewChange() {
        this.sharedService.setDetailPanelView(this.viewOption);
        localStorage.setItem("detail-panel-view", this.viewOption);
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }
}
