import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";

import { TimezonePipe } from "src/app/pipes/timezone.pipe";
import { Constants, TimeZoneT } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { TaskSet, Schedule } from "../automation";
import { AutomationService } from "../automation.service";
import { TitleService } from "../../../services/title.service";
import moment from "moment-timezone";

@Component({
    selector: "app-schedule-form",
    templateUrl: "./schedule-form.component.html",
    providers: [DatePipe, TimezonePipe]
})
export class ScheduleFormComponent implements OnInit {
    taskSet: TaskSet;
    schedule: Schedule;
    taskSetName: string;
    scheduleID: string;

    action: string;
    loading = true;
    saving = false;

    type: string;
    period: number;
    startTime: string;
    endTime: string;
    timeZones = Constants.timeZones;
    selectedTimeZone: TimeZoneT;
    interval: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;

    showFromPicker: boolean;
    showToPicker: boolean;
    fromCounter = 0;
    toCounter = 0;

    constants = Constants;

    intervalOptions = [{ int: "minutes" }, { int: "hours" }, { int: "days" }, { int: "weeks" }, { int: "months" }];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private as: AutomationService,
        private datePipe: DatePipe,
        private timeZonePipe: TimezonePipe,
        private sharedService: SharedService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.taskSetName = params.get("name");
            this.scheduleID = params.get("id");
            this.action = params.get("action");

            if (this.taskSetName) {
                this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));
                // Check if found in cache, if not get
                if (this.sharedService.isEmptyObject(this.taskSet)) {
                    this.as
                        .refreshTaskSets(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.taskSet = Object.assign({}, this.as.getCachedTaskSet(this.taskSetName));
                            this.getSchedule();
                            this.loading = false;
                        });
                } else {
                    this.getSchedule();
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    getSchedule() {
        if (this.taskSet.schedules.length > 0 && this.scheduleID)
            this.schedule = this.taskSet.schedules.find(s => s.id === parseInt(this.scheduleID, 10));
        this.prepForm();
    }

    prepForm() {
        if (this.action && this.schedule) {
            if (this.action === "edit") {
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.schedule.name = "";
            }

            if (this.schedule.timezone) {
                this.selectedTimeZone = this.timeZonePipe.transform(this.schedule.timezone, "utc");
            }

            if (this.schedule.period_param === 1 && this.schedule.period_type === "days") {
                this.type = "daily";
                this.interval = "days";
            } else if (this.schedule.period_param === 1 && this.schedule.period_type === "weeks") {
                this.type = "weekly";
                this.interval = "days";
            } else if (this.schedule.period_param === 1 && this.schedule.period_type === "months") {
                this.type = "monthly";
                this.interval = "days";
            } else if (this.schedule.period_param === 1 && this.schedule.period_type === "once") {
                this.type = "once";
                this.interval = "days";
            } else if (this.schedule.period_param === 1 && this.schedule.period_type === "last_day_of_month") {
                this.type = "last_day_of_month";
                this.interval = "days";
            } else {
                this.type = "every";
                this.interval = this.schedule.period_type;
            }

            this.period = this.schedule.period_param;

            if (this.schedule.start_time) {
                this.startTime = this.schedule._frontData.tz_start_time;
            }
            if (this.schedule.end_time) {
                this.endTime = this.schedule._frontData.tz_end_time;
            }
        }

        if (!this.schedule && !this.isClone && !this.isEdit) {
            this.schedule = new Schedule();
            this.schedule.name = "";

            const date = new Date();
            let offset = new Date().getTimezoneOffset() / -60;
            if (!this.sharedService.isDST(date)) offset = offset + 1;
            this.selectedTimeZone = _.find(this.timeZones, tz => {
                return tz.offset === offset;
            });

            this.schedule.period_type = "once";
            this.type = this.schedule.period_type;
            this.period = 1;
            this.interval = "days";
        }

        // Set Title
        this.titleService.setTitle("SCHEDULE", this.action, this.schedule);
    }

    // check if endDate is after startDate
    checkDate(startDate?, endDate?) {
        if (startDate && endDate) {
            if (new Date(startDate) > new Date(endDate) || startDate === endDate) {
                return true;
            } else return false;
        } else return false;
    }

    sameDate() {
        if (!this.endTime) return false;
        else if (this.startTime === this.endTime) return true;
        else return false;
    }

    ngOnInit() {
        this.prepForm();
    }

    async onSubmit() {
        if (this.checkDate(this.startTime, this.endTime)) return;

        this.saving = true;

        let periodParam = null;
        let periodType = null;

        if (this.type !== "every") {
            periodParam = 1;
            if (this.type === "daily") periodType = "days";
            else if (this.type === "weekly") periodType = "weeks";
            else if (this.type === "monthly") periodType = "months";
            else periodType = this.type;
        } else {
            periodType = this.interval;
            periodParam = this.period;
        }

        const selectedTimeZone = this.selectedTimeZone.utc[0];

        const model = {
            name: this.schedule.name,
            is_enabled: this.schedule.is_enabled,
            period_type: periodType,
            period_param: periodParam,
            start_time: moment(this.startTime).tz(selectedTimeZone, true).format(),
            end_time: this.endTime ? moment(this.endTime).tz(selectedTimeZone, true).format() : null,
            timezone: selectedTimeZone
        };

        if (this.isEdit) {
            const result = await this.as.updateSchedule(this.schedule, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.automation, this.taskSet.name]);
            } else this.saving = false;
        } else {
            const result = await this.as.addSchedule(this.taskSet, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.automation, this.taskSet.name]);
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone) this.router.navigate([Constants.urls.automation, this.taskSetName]);
        else this.router.navigate([Constants.urls.automation, this.taskSetName]);
    }

    // From
    toggleFromPicker() {
        this.showFromPicker = true;
    }

    closeFromPicker() {
        window.focus();
        this.fromCounter = 0;
        this.showFromPicker = false;
    }

    clickOutsideFromPicker() {
        this.fromCounter = this.fromCounter + 1;
        if (this.fromCounter > 1) {
            this.closeFromPicker();
        }
    }

    clearFromDate() {
        this.startTime = null;
    }

    fromDateChanged(event: Date) {
        if (event !== null) {
            this.startTime = moment(this.startTime).format(this.constants.DATETIME_SELECTOR_FORMAT);
        }
    }

    // To
    toggleToPicker() {
        this.showToPicker = true;
    }

    closeToPicker() {
        window.focus();
        this.toCounter = 0;
        this.showToPicker = false;
    }

    clickOutsideToPicker() {
        this.toCounter = this.toCounter + 1;
        if (this.toCounter > 1) {
            this.closeToPicker();
        }
    }

    clearToDate() {
        this.endTime = null;
    }

    toDateChanged(event: Date) {
        if (event !== null) {
            this.endTime = moment(this.endTime).format(this.constants.DATETIME_SELECTOR_FORMAT);
        }
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }
}
